<template>
  <div class="container" style="min-height: 100%; ">
    <van-nav-bar :title="school.name"  left-text="返回"  left-arrow @click-left="onClickLeft" ></van-nav-bar>
    <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white" v-if="!school.imageList || school.imageList.length===0">
      <van-swipe-item >
        <van-image width="100%" fit="cover" :src="require('../assets/school-detail-banner1.jpg')"  />
      </van-swipe-item>
    </van-swipe>
    <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white" v-else>
      <van-swipe-item v-for="(image, index) in school.imageList" :key="index">
        <van-image width="100%" fit="cover" :src="`${assetsHost}${image}`"  />
      </van-swipe-item>
    </van-swipe>
    <div style="padding:12px 16px 12px 16px;">
      <div><b>{{school.name}}</b></div>
      <div style="padding: 8px 0px;color: #999;">{{school.address}}</div>
      <div style="color: #999;font-size: 13px;" v-if="school.contactTel"><van-icon name="phone-o" /> {{school.contactTel}}</div>
      <p style="color: #999;font-size: 13px;">{{school.schoolDesc}}</p>

    </div>
    <van-cell-group inset title="报考驾照（必选一个）" v-if="school.licenses && school.licenses.length > 0">
      <van-cell clickable :title="item.feeName" @click="licenseCheckChange(item)" v-for="(item, index) in school.licenses" :key="index">
        <template #right-icon>
          <span style="display: inline-block;margin-right: 10px;color:#f0440a;">{{`￥${item.price}`}}</span>
          <van-checkbox name="license" v-model="item.checked"></van-checkbox>
        </template>
      </van-cell>
    </van-cell-group>
    <van-cell-group inset title="附加服务（可选多个）" v-if="school.services && school.services.length > 0">
      <van-cell clickable :title="item.feeName" @click="serviceCheckChange(item)" v-for="(item, index) in school.services" :key="index">
        <template #right-icon>
          <span style="display: inline-block;margin-right: 10px;color:#f0440a;">{{`￥${item.price}`}}</span>
          <van-checkbox name="license" v-model="item.checked"></van-checkbox>
        </template>
      </van-cell>
    </van-cell-group>
    <div style="padding: 12px 24px 32px 24px">
      <van-cell-group>
        <van-field v-model="name" label="姓名" maxlength="20" required placeholder="请输入您的姓名" />
        <van-field v-model="phone" label="手机" type="tel" maxlength="11" required placeholder="请输入您的手机" />
        <van-field v-model="identifyNumber" label="身份证号" type="tel" maxlength="18" required placeholder="请输入您的身份证号" />
      </van-cell-group>
    </div>

    <div style="padding-left:32px;">
      <span  style="float:left;display: inline-block;margin-right:12px;"><van-checkbox v-model="agreementChecked"></van-checkbox></span>
      <span>  同意<span style="color:#ff0000;" @click="showAgreement = true">《用户协议》</span></span>
    </div>

    <div style="padding:30px 24px 64px 24px;" v-if="(school.licenses && school.licenses.length > 0) || (school.services && school.services.length > 0)">
      <van-button :loading="loading" type="primary" block @click="toSubmit">提交报名{{ formData.totalFee===0 ? '': `（总价格￥${formData.totalFee}元）` }}</van-button>
    </div>

    <van-popup v-model="showAgreement" round position="bottom" :style="{ height: '60%' }">
      <div style="padding:12px 24px;">
        <h3 style="text-align: center;margin:12px 0px 20px 0px;">用户协议</h3>
        <div v-html="school.agreement"></div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import  {API_HOST, ASSETS_HOST } from '@/utils/constant'

import { Toast } from 'vant';
export default {
  props: [],
  components: {},
  data() {
    return {
      schoolId: null,
      assetsHost: ASSETS_HOST,
      showAgreement: false,
      agreementChecked: true,
      result: [],
      loading: false,
      name: '',
      phone: '',
      user: {},
      identifyNumber: '',
      school: {
        licenses: [],
        services: []
      },
      formData: {
        license: null,
        totalFee: 0
      },
      type: null
    }
  },
  created() {
    this.schoolId = this.$route.query.id
    this.type = this.$route.query.type

    this.loadSchool()
    this.loadCurrentUser()
  },
  methods: {
    loadCurrentUser () {
      this.$http.get(`${API_HOST}/h5/userInfo`, {
        headers: {
          authtoken: localStorage.getItem("authtoken")
        }
      }).then((response) => {
        if (response.status === 200) {
          const res = response.data
          if (res.code === 0 && res.ok) {
            this.user = res.data
          } else {
            Toast(res.msg)
          }
        } else {
          Toast('网络请求失败')
        }
      })
    },
    loadSchool () {
      if (!this.schoolId) {
        return
      }
      this.$http.get(`${API_HOST}/public/driver-school/${this.schoolId}`).then((response) => {
        if (response.status === 200) {
          const res = response.data
          if (res.code === 0 && res.ok) {
            this.school = res.data
          } else {
            Toast(res.msg)
          }
        }  else {
          Toast('网络请求失败')
        }
      })
    },
    toSubmit() {
      if (!this.agreementChecked) {
        Toast('请同意用户协议')
        return
      }
      if (this.name.trim().length === 0) {
        Toast('请输入您的姓名')
        return
      }
      if (this.phone.trim().length === 0) {
        Toast('请输入您的手机')
        return
      }
      if (this.identifyNumber.trim().length === 0) {
        Toast('请输入您的身份证号')
        return
      }
      let items = []
      this.school.licenses.forEach(item => {
        if (item.checked) {
          items.push(item)
        }
      })
      if (items.length === 0) {
        Toast('请选择报考驾照')
        return
      }
      this.school.services.forEach(item => {
        if (item.checked) {
          items.push(item)
        }
      })

      const data = {
        fees: items,
        name: this.name,
        phone: this.phone,
        schoolId: this.schoolId,
        identifyNumber: this.identifyNumber,
        iamMemberId: this.user.id
      }
      this.loading = true
      this.$http.post(`${API_HOST}/public/placeorder`, data).then((response) => {
        this.loading = false
        if (response.status === 200) {
          const res = response.data
          if (res.code === 0 && res.ok) {
            if (res.data.businessSuccess) {
              window.location.href = res.data.code_url
            } else {
              Toast(res.data.businessError)
            }
          } else {
            Toast(res.msg)
          }
        }  else {
          Toast('网络请求失败')
        }
      })


    },
    calcTotalFee() {
      let total = 0;
      if (this.school.licenses) {
        this.school.licenses.forEach(license => {
          if (license.checked) {
            total += license.price
          }
        })
      }
      if (this.school.services) {
        this.school.services.forEach(service => {
          if (service.checked) {
            total += service.price
          }
          this.formData.totalFee = total
        })
      }

    },
    licenseCheckChange (item) {
      this.school.licenses.forEach(license => {
        license.checked = false
      })
      item.checked = true
      this.formData.license = item.id
      this.calcTotalFee()
    },
    serviceCheckChange (item) {
      item.checked = !item.checked
      this.calcTotalFee()
    },
    onClickLeft() {
      this.$router.push(`/school-list?type=${this.type}`)
    }
  },
  fillter: {},
}
</script>

<style scoped>

.container {  }
</style>
